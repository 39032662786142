// job_history_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["similarJobs"]
  static values = {
    jobId: String,
    title: String,
    occupation: String,
    income: String,
    place: String,
    industry: String,
    skill: String,
    companyId: String,
    platform: { type: String, default: 'direct' },
    incomeIcon: String,
    placeIcon: String,
    occupationIcon: String,
    industryIcon: String,
    skillIcon: String
  }

  connect() {
    this.saveJobHistory()
    this.loadRecentJobs()
  }

  saveJobHistory() {
    if (!this.jobIdValue?.trim()) return;

    const jobHistory = JSON.parse(localStorage.getItem('recentlyJob') || '[]')

    // Check if job already exists
    const existingJobIndex = jobHistory.findIndex(job => job.jobId === this.jobIdValue)
    if (existingJobIndex > -1) {
      jobHistory.splice(existingJobIndex, 1)
    }

    // Add new job to front of array
    jobHistory.unshift({
      platform: this.platformValue,
      jobId: this.jobIdValue,
      title: this.titleValue,
      occupation: this.occupationValue,
      income: this.incomeValue,
      place: this.placeValue,
      industry: this.industryValue,
      skill: this.skillValue,
      companyId: this.companyIdValue
    })

    // Keep only 5 most recent
    if (jobHistory.length > 6) {
      jobHistory.pop()
    }

    localStorage.setItem('recentlyJob', JSON.stringify(jobHistory))
  }

  loadRecentJobs() {
    const jobHistory = JSON.parse(localStorage.getItem('recentlyJob') || '[]')
    if (jobHistory.length === 0) {
      this.similarJobsTarget.classList.add('d-none')
      return
    }

    // Return if current job is only job in history
    if (jobHistory.length === 1 && jobHistory[0].jobId === this.jobIdValue) {
      this.similarJobsTarget.classList.add('d-none')
      return
    }

    const recentJobs = jobHistory
      .filter(job => job.jobId !== this.jobIdValue)
      .slice(0, 5) // Show max 4 other jobs

    if (recentJobs.length > 0) {
      this.similarJobsTarget.querySelector('.similar-jobs').innerHTML = this.buildJobsHtml(recentJobs)
    }
  }

  buildJobsHtml(jobs) {
    return jobs.map(job => `
      <section class="size-12 border-top py-2 px-3">
        <a href="/jobs/${job.jobId}?${job.platform}=true" class="link-info text-black text-decoration-none mb-0" target="_blank" rel="noopener">
          <h4 class="fw-bold size-12 lh-18 text-decoration-none text-break">${job.title}</h4>
          <div class="d-block gap-2">
            ${this.buildAttributeHtml(job.income, this.incomeIconValue, 'Income icon')}
            ${this.buildAttributeHtml(job.place, this.placeIconValue, 'Place icon')}
            ${this.buildAttributeHtml(job.occupation, this.occupationIconValue, 'jobType icon')}
            ${this.buildAttributeHtml(job.industry, this.industryIconValue, 'Industry icon')}
            ${this.buildAttributeHtml(job.skill, this.skillIconValue, 'Skill icon')}
          </div>
        </a>
      </section>
    `).join('')
  }

  buildAttributeHtml(value, icon, alt) {
    if (!value) return ''

    return `
      <div class="job-attribute d-flex align-items-center">
        <img src="${icon}" alt="${alt}" class="pe-1 py-1">
        <span class="text-truncate">${value}</span>
      </div>
    `
  }
}
