// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';

export default class extends Controller {
  static targets = ['form', 'title', 'text', 'file'];
  static values = {
    wait: { type: Number, default: 500 }
  }

  connect() {
    this.canSubmit = true;
    const validate = new JustValidate(
      this.formTarget,
      {
        errorFieldCssClass: ['is-invalid'],
      }
    );

    validate
      .addField(this.titleTarget, [
        {
          rule: 'required',
          errorMessage: 'タイトルは必須項目です',
        },
        {
          rule: 'maxLength',
          value: 255,
          errorMessage: 'タイトルは255文字以内で入力してください',
        }
      ])
      .addField(this.textTarget, [
        {
          rule: 'required',
          errorMessage: '本文は必須項目です',
        },
        {
          rule: 'maxLength',
          value: 1000,
          errorMessage: '本文は1000文字以内で入力してください',
        }
      ])
      .addField(this.fileTarget, [
        {
          rule: 'files',
          value: {
            files: {
              extensions: ['png', 'jpeg', 'jpg', 'pdf', 'xlsx', 'docx']
            },
          },
          errorMessage: 'ファイルの拡張子が正しくありません。png、jpeg、jpg、pdf、xlsx、docxのいずれかのファイルを選択してください。',
        },
      ])
      .onSuccess(( event ) => {
        this.onSubmitForm(event);
      });
  }

  onSubmitForm(event) {
    if (this.canSubmit) {
      event.target.submit();
      this.canSubmit = false;

      setTimeout(() => {
        this.canSubmit = true;
      }, this.waitValue);
    } else {
      event.preventDefault();
    }
  }
}
