// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['counter']

  connect() {
    this.updateAllCounters()
  }

  updateAllCounters() {
    this.counterTargets.forEach(counter => {
      const input = this.element.querySelector(`[data-counter-attribute-param="${counter.dataset.counter}"]`)
      if (input) {
        this.updateCounter(counter, input.value.length, parseInt(input.dataset.counterMaxTextLengthParam))
      }
    })
  }

  updateCounter(counter, characters, maxTextLength) {
    counter.textContent = characters
    counter.classList.toggle('text-danger', characters > maxTextLength)
  }

  onCountCharacters(event) {
    const { currentTarget, params } = event
    const characters = currentTarget.value.length
    const counter = this.counterTargets.find(target => target.dataset.counter === params.attribute)

    if (counter) {
      this.updateCounter(counter, characters, parseInt(params.maxTextLength))
    }
  }
}
