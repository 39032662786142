// @ts-nocheck
import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ["entries", "pagination", "likeStory"];

  connect() {
    this.save = debounce(this.save, 700);
  }

  onScroll() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 576) { return }

    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (nextPage == null) { return }

    const url = nextPage.href;
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.pageYOffset >= height - window.innerHeight - 1) {
      this.loadMore(url)
    }
  }

  async loadMore(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (response) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", response.entries);
        this.paginationTarget.innerHTML = response.pagination
      }
    });
  }

  onLikeStory(event) {
    const candidateId = event.params.candidateId
    if (!candidateId) {
      return window.location.href = '/candidate/login'
    }
    const image = event.currentTarget.parentNode;
    this._loadLikeStory(image)
    this.save(event);
  }

  onLikeStoryDetail(event) {
    this.onLikeStory(event);

    const btnStory = document.querySelector('.btn-like-story');
    this._loadLikeStory(btnStory);

    btnStory.dataset.storyLikeParam = btnStory.dataset.storyLikeParam === 'false' ? true : false;
  }

  onLikeButtonStoryDetail(event) {
    this.onLikeStory(event);
    event.currentTarget.dataset.storyLikeParam = !event.params.like;

    const image = document.querySelector('.like-story');
    this._loadLikeStory(image)
  }

  save(event) {
    const isLikeStatus = !event.params.like;
    const method = isLikeStatus ? "DELETE" : "PATCH";
    const url = event.params.url;
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json"
    });
  }

  _loadLikeStory(image) {
    const amountLike = image.querySelector('.js-count-like');
    const unlikeStory = image.querySelector('.js-unlike-story');
    const likeStory = image.querySelector('.js-like-story');

    const isUnlikeStoryDisabled = unlikeStory.classList.contains('disabled-image');
    const increment = isUnlikeStoryDisabled ? -1 : 1;

    amountLike.textContent = parseInt(amountLike.textContent) + increment;
    unlikeStory.classList.toggle('disabled-image');
    likeStory.classList.toggle('disabled-image');
  }
}
