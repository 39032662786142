// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import { V1_GET_JOBS_AGENT, V1_SAVE_CONDITION_JOB_SEARCH, AGENT_SEARCH_PATH } from "@/constant/routers/path";


export default class extends Controller {
  static targets = ['form', 'modal', 'items', 'prefectures', 'cities', 'prefectureChildren', 'industries', 'skills',
    'jobFeatures', 'itemOccupationChecked', 'itemPrefectureChecked', 'itemIndustryChecked',
      'itemSkillChecked', 'itemJobFeatureChecked', 'jobCount', 'freeWord', 'title'];

  connect() {
    if (this.hasFormTarget) {
      this.onSearchCountJob();
      this.titleTarget.value = this._getCurrentDate();
    }
  }

  onOpen(event) {
    const btnRegisterFooter = document.getElementById('btnRegisterFooter');
    if (btnRegisterFooter) {
      btnRegisterFooter.style.zIndex = 2;
    }
    const newDiv = document.createElement("div");
    newDiv.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(newDiv)
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "0px";
    this._toggleModal(`searchCondition`)
    document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
  }

  onToggleModal(event) {
    this._toggleModal(event.params.id)
  }

  _toggleModal(id) {
    const modalElement = document.getElementById(`${id}`);
    if (modalElement.classList.contains("show")) {
      modalElement.classList.remove("show");
      document.body.classList.remove("modal-open");
      modalElement.style.display = "none";
    } else {
      modalElement.classList.add("show");
      document.body.classList.add("modal-open");
      modalElement.style.display = "block";
    }
  }

  onCloseModal() {
    const btnRegisterFooter = document.getElementById('btnRegisterFooter');
    if (btnRegisterFooter) {
      btnRegisterFooter.style.zIndex = 1030;
    }
    document.querySelectorAll(".modal-backdrop").forEach((element) => {
      element.classList.remove("modal-backdrop", "fade", "show")
    });
    this._toggleModal(`searchCondition`)
    document.body.style.overflow = "";
  }

  onSaveOccupation(event) {
    let $itemChecked = this.itemOccupationCheckedTarget;
    let contentHTML = '';
    this.itemsTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
    this._toggleModal(event.params.id);
  }

  onSavePrefecture(event) {
    let $itemChecked = this.itemPrefectureCheckedTarget;
    let contentHTML = '';
    this.prefecturesTargets.map(item => {
      if (item.querySelector('input').checked) {
        let prefectureContent = '';
        const prefectureId = item.querySelector('input').value
        prefectureContent += item.querySelector(".form-check-label[for='prefecture_sp_id_" + prefectureId + "']").textContent
        item.querySelectorAll("input[name='prefecture_" + prefectureId + "_city']").forEach(city => {
          const cityId = city.value
          if (city.checked) {
            prefectureContent += '/' + item.querySelector(".form-check-label[for='prefecture_sp_id_" + cityId + "']").textContent
          }
        })
        contentHTML += "<span class='badge bg-light border text-dark fw-normal size-12 lh-12'> " + prefectureContent +
          "<button class='btn-close btn-close-primary size-10 ms-2 color-primary' type='button' data-action='click->search-condition#onRemovePrefecture' data-search-condition-checkbox-param='" + event.params.attribute + "_" + prefectureId + "'></button></span>";
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
    this._toggleModal(event.params.id);
  }

  onSaveIndustry(event) {
    let $itemChecked = this.itemIndustryCheckedTarget;
    let contentHTML = '';
    this.industriesTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
    this._toggleModal(event.params.id);
  }

  onSaveSkill(event) {
    let $itemChecked = this.itemSkillCheckedTarget;
    let contentHTML = '';
    this.skillsTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
    this._toggleModal(event.params.id);
  }

  onSaveJobFeature(event) {
    let $itemChecked = this.itemJobFeatureCheckedTarget;
    let contentHTML = '';
    this.jobFeaturesTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
    this._toggleModal(event.params.id);
  }

  onSearchFreeWord() {
    event.preventDefault();
    this.onSearchCountJob();
  }

  onClearSearchCondition() {
    document.getElementById('form-search-condition').reset();
    this.itemOccupationCheckedTarget.querySelectorAll('span').forEach(e => e.remove());
    this.itemPrefectureCheckedTarget.querySelectorAll('span').forEach(e => e.remove());
    //this.itemIndustryCheckedTarget.querySelectorAll('span').forEach(e => e.remove());
    //this.itemSkillCheckedTarget.querySelectorAll('span').forEach(e => e.remove());
    this.itemJobFeatureCheckedTarget.querySelectorAll('span').forEach(e => e.remove());
    this.onSearchCountJob();
  }

  onSearchCountJob() {
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    const params = new URLSearchParams(formData)

    // 収入IDから実際の金額（万円単位）への変換マップ
    const incomeMap = {
      '1': 150,
      '2': 200,
      '3': 250,
      '4': 300,
      '5': 350,
      '6': 400,
      '7': 450,
      '8': 500,
      '9': 550,
      '10': 600,
      '11': 650,
      '12': 700,
      '13': 750,
      '14': 800,
      '15': 850,
      '16': 900,
      '17': 950,
      '18': 1000,
      '19': 1100,
      '20': 1200,
      '21': 1300,
      '22': 1400,
      '23': 1500,
      '24': 1600,
      '25': 1700,
      '26': 1800,
      '27': 1900,
      '28': 2000,
      '29': 2500,
      '30': 3000,
      '31': 3500,
      '32': 4000,
      '33': 4500,
      '34': 5000,
      '35': null
    };

    for (const [key, value] of formData.entries()) {
      if (key === 'wage_min' && value) {
        const amount = incomeMap[value] || value;
        params.append('filter[wage_min][gteq]', amount);
      } else if (key === 'wage_max' && value) {
        const amount = incomeMap[value] || value;
        params.append('filter[wage_max][lteq]', amount);
      } else if (key === 'job_types[]' && value) {
        params.append('filter[have_hrc_job_types_any][]', value);
      } else if (key === 'prefectures[]' && value) {
        params.append('filter[with_prefecture][]', value);
      // クイックサーチからスキル・業種・キーワードを指定すると
      // 意図しないリダイレクトが発生するため、コメントアウト
      // https://aim-f.backlog.com/view/MATCH-472#comment-515099358
      //} else if (key === 'keywords' && value) {
      //  params.append('keywords', value);
      //} else if (key === 'skills' && value) {
      //  params.append('filter[have_hrc_skills_any][]', value);
      //} else if (key === 'industries' && value) {
      //  params.append('filter[have_corporation_industries_any][]', value);
      } else if (value) {
        params.append(key, value);
      }
    }
    
    params.append('include[]', 'corporation');
    params.append('include[]', 'prefectures');
    params.append('include[]', 'hrc_job_types');
    //params.append('include[]', 'hrc_skills');
    //params.append('include[]', 'corporation.corporation_industries');
    
    params.append('sort', '-publication_date_senavi');
    params.append('page[size]', 0); // カウント値を取るだけなのでページサイズを0にする（パフォーマンス改善）
    params.append('page[number]', 1);

    const queryString = params.toString();
    const url = V1_GET_JOBS_AGENT + "?" + queryString;

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (response) => {
        const { data: { meta: { total_records } } } = response;
        this.updateJobCount(this.jobCountTarget, total_records);
      }
    });
  }

  onSearchCondition() {
    event.preventDefault();
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);

    // URLSearchParamsを使用して複数の同じキーを持つパラメータを処理
    const searchParams = new URLSearchParams();
    
    // 既存のパラメータをコピー
    for (const [key, value] of formData.entries()) {
      // パラメータ名を変換
      // クイックサーチからスキル・業種・キーワードを指定すると
      // 意図しないリダイレクトが発生するため、コメントアウト
      // https://aim-f.backlog.com/view/MATCH-472#comment-515099358
      //if (key === 'filter[have_corporation_industries_any][]') {
      //  searchParams.append('industries', value);
      //} else if (key === 'filter[have_hrc_skills_any][]') {
      //  searchParams.append('skills', value);
      if (key === 'job_types[]') {
        searchParams.append('job_types', value);
      } else if (key === 'prefectures[]') {
        searchParams.append('prefectures', value);
      } else {
        searchParams.append(key, value);
      }
    }
    
    const queryString = searchParams.toString();
    
    let url;
    if (AGENT_SEARCH_PATH.includes('?')) {
      url = AGENT_SEARCH_PATH + '&' + queryString;
    } else {
      url = AGENT_SEARCH_PATH + '?' + queryString;
    }
    window.location.replace(url);
  }

  onSaveSearchCondition() {
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    formData.append('title', this.titleTarget.value)
    const url = V1_SAVE_CONDITION_JOB_SEARCH;

    Rails.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: formData,
      success: () => {
        document.querySelector('#save-condition-success')?.remove();
        document.getElementById('form-search-condition').insertAdjacentHTML('beforebegin', '<div class="alert alert-success alert-dismissible fade show rounded-0 m-0"' +
          'id="save-condition-success" role="alert">更新が正常に完了しました。<button aria-label="Close" class="btn-close" data-bs-dismiss="alert"></button></div>')
      },
      error: (response) => {
        if (response.code == 'unauthorized') {
          window.location.href = '/candidate/login'
        }
        else {
          document.querySelector('#save-condition-fail')?.remove();
          document.getElementById('form-search-condition').insertAdjacentHTML('beforebegin', '<div class="alert alert-danger alert-dismissible fade show rounded-0 m-0"' +
            'id="save-condition-fail" role="alert">更新が中断されました。<button aria-label="Close" class="btn-close" data-bs-dismiss="alert"></button></div>')
        }
      }
    });
    this._toggleModal(event.params.id);
  }

  getFreeWord() {
    if (this.freeWordTarget) {
      return this.freeWordTarget.value
    }
  }

  getOccupationIds(form) {
    return form.getAll('occupation_ids');
  }

  getPrefectureIds(form) {
    return form.getAll('prefecture_ids');
  }

  getCityIds(form, prefectures) {
    let cityIds = []
    prefectures.forEach(item => {
      cityIds = cityIds.concat(form.getAll('prefecture_' + item + '_city'))
    })
    return cityIds
  }

  getIndustryIds(form) {
    return form.getAll('insdustry_ids');
  }

  getSkillIds(form) {
    return form.getAll('skill_ids');
  }

  getJobFeatureIds(form) {
    return form.getAll('job_feature_ids');
  }

  getAnnualIncome(form) {
    let minIncome = form.get('min_income_id');
    let maxIncome = form.get('max_income_id');

    return { min_income: minIncome, max_income: maxIncome }
  }

  getEmploymentType(form) {
    return form.getAll('employment_type_ids')
  }

  onSaveItem(e, item) {
    let tag = '';
    if (item.querySelector('input').checked) {
      let label = item.querySelector('.form-check-label').textContent;
      let id = item.querySelector('input').value;
      tag = "<span class='badge bg-light border text-dark fw-normal size-12 lh-12'> " + label +
        "<button class='btn-close btn-close-primary size-10 ms-2 color-primary' type='button' data-action='click->search-condition#onRemove' data-search-condition-checkbox-param='" + e.params.attribute + "_" + id + "'></button></span>";
    }

    return tag;
  }

  onRemove(event) {
    event.target.parentNode.remove();
    document.getElementById(event.params.checkbox).checked = false;
    this.onSearchCountJob();
  }

  onRemovePrefecture() {
    event.target.parentNode.remove();
    document.getElementById(event.params.checkbox).checked = false;
    document.querySelectorAll(`.parent_${event.params.checkbox}:checked`).forEach(item => {
      item.checked = false;
    });
    this.onSearchCountJob();
  }

  onDisableCity(event) {
    const citiyItems = document.getElementsByClassName(`parent_${event.target.id}`);
    const prefectureChecked = event.target.checked;
    Array.from(citiyItems).forEach(item => {
      if (prefectureChecked) {
        item.disabled = false
      } else {
        item.disabled = true
      }
    })
  }

  updateJobCount = (element, number) => {
    let upto = 0;
    const jump = Math.floor(number / 100) + 1;
    const counts = setInterval(() => {
      upto += jump;
      if (upto > number) {
        upto = number;
      }
      element.textContent = upto;
      if (upto === number) {
        clearInterval(counts);
      }
    }, 20);
  };

  _getFormDataSearchCondition(form, formData) {
    const occupations = this.getOccupationIds(form) || [];
    const prefectures = this.getPrefectureIds(form) || [];
    const cities = this.getCityIds(form, prefectures) || [];
    const annualIncome = this.getAnnualIncome(form) || [];
    // クイックサーチからスキル・業種・キーワードを指定すると
    // 意図しないリダイレクトが発生するため、コメントアウト
    // https://aim-f.backlog.com/view/MATCH-472#comment-515099358
    //const freeWords = (this.getFreeWord() || '').trim().split(/[\s　]+/) || [];
    //const industries = this.getIndustryIds(form) || [];
    //const skills = this.getSkillIds(form) || [];

    occupations.forEach(occupation => formData.append("job_types[]", occupation));
    prefectures.forEach(prefecture => formData.append("prefectures[]", prefecture));
    // クイックサーチからスキル・業種・キーワードを指定すると
    // 意図しないリダイレクトが発生するため応急対応としてコメントアウト
    // https://aim-f.backlog.com/view/MATCH-472#comment-515099358
    //industries.forEach(industry => formData.append("filter[have_corporation_industries_any][]", industry));
    //skills.forEach(skill => formData.append("filter[have_hrc_skills_any][]", skill));
    //freeWords.forEach(freeWord => formData.append("filter[contain_keywords_any][]", freeWord));
    if (annualIncome.min_income != '') {
      formData.append('wage_min', annualIncome.min_income);
    }
    if (annualIncome.max_income != '') {
      formData.append('wage_max', annualIncome.max_income);
    }
  }

  _getCurrentDate() {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0')
    const time = String(currentDate.getHours()).padStart(2, '0') + ':' + String(currentDate.getMinutes()).padStart(2, '0') + ':' + String(currentDate.getSeconds()).padStart(2, '0')

    return currentDate.getFullYear() + '/' + month + '/' + day + ' ' + time
  }
}
