// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import { object, string } from 'yup';

export default class extends Controller {
  static targets = ['input', 'errors'];
  static values = {
    title: String,
    maxLength: { type: Number, default: 255 },
    maxLengthString: { type: String, default: '' }
  }

  connect() {
    this.initializeSchema();
  }

  initializeSchema() {
    const maxLengthMessage = `${this.titleValue}は${this.maxLengthStringValue || this.maxLengthValue}文字以内で入力してください`;
    this.inputSchema = object({
      text: string().test(
        'maxLength',
        maxLengthMessage,
        value => value.length <= this.maxLengthValue
      )
    });
  }

  onChange() {
    this.validateInput();
  }

  validateInput() {
    try {
      this.clearErrors();
      this.inputSchema.validateSync({ text: this.inputTarget.value }, { abortEarly: false });
    } catch (error) {
      this.displayErrors(error);
    }
  }

  clearErrors() {
    this.errorsTarget.innerHTML = '';
    this.inputTarget.classList.remove('is-invalid');
  }

  displayErrors(error) {
    const errorMessages = error.inner.map(err => err.message);
    const errorElement = this.createErrorElement(errorMessages);
    this.errorsTarget.appendChild(errorElement);
  }

  createErrorElement(messages) {
    const divElement = document.createElement('div');
    divElement.className = 'invalid-feedback size-14 d-block fw-400';
    divElement.textContent = messages.join(', ');
    return divElement;
  }
}
