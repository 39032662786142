// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['notice'];

  onCloseModal = () => {
    this.noticeTarget.remove();
  }
}
