// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["entries", "pagination"];

  connect() {
    this._checkNavigation()
    const interviewModalId = localStorage.getItem("interview_modal_id");
    const interviewButton = document.getElementById("js-candidate-apply-job-" + interviewModalId);

    if (interviewButton) {
      this._showInterviewModal(interviewButton);
    }
  }

  _checkNavigation() {
    if (performance.getEntriesByType) {
      const navigation = performance.getEntriesByType('navigation')[0]
      if (navigation.type === 'back_forward' || navigation.type === 2) {
        this._updateItemsStates();
      }
    } else if (performance.navigation) {
      if (performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD) {
        this._updateItemsStates();
      }
    }
  }

  onScroll() {
    if (this.debouncedScroll) {
      clearTimeout(this.debouncedScroll);
    }

    this.debouncedScroll = setTimeout(() => {
      const nextPage = this.paginationTarget.querySelector("a[rel='next']");
      if (nextPage == null) { return }

      const url = nextPage.href;
      const body = document.body;
      const html = document.documentElement;
      const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      if (window.pageYOffset >= height - window.innerHeight - 10) {
        this._loadMore(url)
      }
    }, 200);
  }

  onBookmarkedCompany(event) {
    event.preventDefault();
    const propositionId = event.params.propositionId
    Rails.ajax({
      type: "PUT",
      url: event.params.url,
      dataType: "json",
      success: (response) => {
        document.getElementById(`favorite-button-${propositionId}`).innerHTML = response.favourite_btn;
      },
      error: (xhr, status, error) => {
        switch (error.status) {
          case 404:
            const element = document.getElementById('js-candidate-apply-job-' + propositionId);
            const favButton = document.getElementById('fav-btn-' + propositionId);
            const closeEl = document.getElementById('job-suspended-' + propositionId);
            favButton.classList.add('disabled', 'bg-grey-03', 'border-0');
            favButton.attributes.removeNamedItem('data-action');
            element.classList.remove('btn-primary');
            element.classList.add('disabled', 'bg-grey-03', 'border-0', 'btn-outline-secondary');
            element.attributes.removeNamedItem('data-bs-target');
            closeEl.classList.toggle('d-none');
            break;
          case 500:
            alert('サーバー内部でエラーが発生しました。時間をおいて再度お試しください。');
            break;
          default:
            console.error(`Unexpected error: ${error.status}`);
        }
      }
    });
  }

  onCreateApplyJob(event) {
    const url = event.params.url;
    const propositionId = event.params.propositionId;
    const parent = event.target.closest('.modal');
    const message = parent.querySelector('textarea').value;
    const formData = new FormData();
    formData.append('message', message);

    Rails.ajax({
      type: "PUT",
      url: url,
      data: formData,
      dataType: "json",
      success: () => {
        this._toggleModal('js-favorite-company-' + propositionId);
        this._toggleModal('js-apply-success-' + propositionId);
        const element = document.getElementById('js-candidate-apply-job-' + propositionId);
        const favButton = document.getElementById('fav-btn-' + propositionId);
        const applyTag = document.getElementById('apply-tag-' + propositionId);
        const applyTagSpan = document.createElement('span');

        favButton.remove();
        element.textContent = '面談依頼済み';
        element.classList.remove('btn-primary');
        element.classList.add('disabled', 'bg-grey-03', 'border-0', 'btn-outline-secondary');
        element.attributes.removeNamedItem('data-bs-target');
        applyTagSpan.classList.add('bg-orange', 'rounded-pill', 'px-2', 'py-1', 'color-white-primary', 'size-10', 'lh-10', 'h-18x', 'me-2', 'd-flex', 'align-items-center');
        applyTagSpan.textContent = '応募済み';
        applyTag.appendChild(applyTagSpan);
      },
      error: (xhr, status, error) => {
        switch (error.status) {
          case 401:
          case 404:
            const errorMessage = JSON.parse(error.responseText).message;
            alert(errorMessage);
            window.location.reload();
            break;
          case 422:
            const errorResponse = JSON.parse(error.response);
            const errorActions = {
              message: () => {
                const parentEl = document.getElementById('js-favorite-company-' + propositionId);
                const inputEl = parentEl.querySelector('textarea');
                inputEl.classList.add('is-invalid');
              },
              id: () => {
                alert(errorResponse.error.id);
              }
            };

            Object.keys(errorResponse.error).forEach(key => {
              if (errorActions[key]) {
                errorActions[key]();
              } else {
                console.log(`Unhandled error key: ${key}`);
              }
            });
            break;
          case 500:
            alert('サーバー内部でエラーが発生しました。時間をおいて再度お試しください。');
            break;
          default:
            console.error(`Unexpected error: ${error.status}`);
        }
      }
    });
  }

  onCloseModal(event) {
    const id = event.target.closest('.modal').id
    this._toggleModal(id);
    document.querySelectorAll('.modal-backdrop').forEach((element) => {
      element.classList.remove('modal-backdrop', 'fade', 'show')
    });
    document.body.style.overflow = '';
  }

  onSaveInterviewModal = () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    localStorage.setItem("interview_modal_id", event.params.propositionId);
  }

  // private

  _loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (response) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", response.entries);
        this.paginationTarget.innerHTML = response.pagination
      }
    });
  }

  _toggleModal = (id) => {
    const modalElement = document.getElementById(`${id}`);
    if (modalElement.classList.contains('show')) {
      modalElement.classList.remove('show');
      document.body.classList.remove('modal-open');
      modalElement.style.display = 'none';
    } else {
      modalElement.classList.add('show');
      document.body.classList.add('modal-open');
      modalElement.style.display = 'block';
    }
  }

  _showInterviewModal = (button) => {
    const interviewModal = JSON.parse(localStorage.getItem("interview_apply_modal"));
    if (button && interviewModal) {
      button.click();
      localStorage.removeItem("interview_apply_modal");
      localStorage.removeItem("interview_modal_id");
    }
  }

  _updateItemsStates(event) {
    const url = window.location.pathname;
    Rails.ajax({
      type: 'GET',
      url: `${url}?=_${new Date().getTime()}`,
      dataType: 'json',
      success: (response) => {
        this.entriesTarget.innerHTML = response.entries;
      }
    });
  }
}
