export default class ApiLoaderPlugin {
  static DEFAULTS = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
    }
  }

  constructor(element, options = {}) {
    this.element = element
    this.options = this.buildRequestOptions(options)
    this.endpoint = this.buildEndpointWithQuery(element.dataset.apiEndpoint, element.dataset.apiQuery)
    this.dataPath = element.dataset.dataPath
    this.templateSuccessId = element.dataset.templateSuccessId
    this.templateLoadingId = element.dataset.templateLoadingId
    this.templateErrorId = element.dataset.templateErrorId
    this.responseType = element.dataset.responseType || 'json'

    this.contentElement = this.element.querySelector('[data-api-loader-target="content"]')
    if (!this.contentElement) {
      throw new Error('Missing content target element for API Loader')
    }

    this.initialize()
  }

  buildEndpointWithQuery(endpoint, queryString) {
    if (!endpoint) return null
    if (!queryString) return endpoint

    try {
      const queryParams = JSON.parse(queryString)
      const searchParams = new URLSearchParams()

      const flattenParams = (obj, prefix = '') => {
        Object.entries(obj).forEach(([key, value]) => {
          const finalKey = prefix ? `${prefix}[${key}]` : key

          if (Array.isArray(value)) {
            value.forEach(v => searchParams.append(`${finalKey}[]`, v))
          } else if (value !== null && value !== undefined) {
            if (typeof value === 'object') {
              flattenParams(value, finalKey)
            } else {
              searchParams.append(finalKey, value.toString())
            }
          }
        })
      }

      flattenParams(queryParams)
      const queryStr = searchParams.toString()
      return queryStr ? `${endpoint}?${queryStr}` : endpoint
    } catch (error) {
      console.error('Invalid query params:', error)
      return endpoint
    }
  }

  buildRequestOptions(options) {
    const finalOptions = {
      ...ApiLoaderPlugin.DEFAULTS,
      ...options,
      headers: {
        ...ApiLoaderPlugin.DEFAULTS.headers,
        ...(options.headers || {})
      }
    }

    // Chỉ thêm body nếu không phải GET hoặc HEAD
    const method = (finalOptions.method || 'GET').toUpperCase()
    if (method === 'GET' || method === 'HEAD') {
      delete finalOptions.body
    } else if (finalOptions.body && typeof finalOptions.body === 'object') {
      finalOptions.body = JSON.stringify(finalOptions.body)
    }

    return finalOptions
  }

  initialize() {
    this.showLoading()
    this.fetchData()
  }

  async fetchData() {
    try {
      const response = await fetch(this.endpoint, this.options)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = this.responseType === 'json' ? await response.json() : await response.text()
      this.handleSuccess(data)
    } catch (error) {
      this.handleError(error)
    }
  }

  handleSuccess(data) {
    if (this.responseType === 'json') {
      const finalData = this.dataPath ? this.getNestedValue(data, this.dataPath) : data
      this.renderTemplate(finalData)

      const event = new CustomEvent('api-loader:success', {
        detail: { data: finalData },
        bubbles: true
      })
      this.element.dispatchEvent(event)
    } else {
      this.contentElement.innerHTML = data
      this.initializeControllers()

      const event = new CustomEvent('api-loader:success', {
        detail: { data },
        bubbles: true
      })
      this.element.dispatchEvent(event)
    }
  }

  handleError(error) {
    if (this.templateErrorId) {
      const template = document.getElementById(this.templateErrorId)
      if (template) {
        this.contentElement.innerHTML = template.innerHTML
      }
    }
    console.error('Error:', error)
  }

  showLoading() {
    if (this.templateLoadingId) {
      const template = document.getElementById(this.templateLoadingId)
      if (template) {
        this.contentElement.innerHTML = template.innerHTML
      }
    }
  }

  renderTemplate(data) {
    const template = document.getElementById(this.templateSuccessId)
    if (!template) return

    if (Array.isArray(data)) {
      this.contentElement.innerHTML = data.map(item => this.processTemplate(template.innerHTML, item)).join('')
    } else {
      this.contentElement.innerHTML = this.processTemplate(template.innerHTML, data)
    }

    this.initializeControllers()
  }

  processTemplate(template, data) {
    return template.replace(/\{\{(.*?)\}\}/g, (match, key) => {
      const trimmedKey = key.trim()
      return this.getNestedValue(data, trimmedKey) || ''
    })
  }

  getNestedValue(obj, path) {
    return path.split('.').reduce((current, key) => current?.[key], obj)
  }

  initializeControllers() {
    if (window.Stimulus) {
      const controllers = this.element.querySelectorAll('[data-controller]')
      controllers.forEach(controller => {
        window.Stimulus.getControllerForElementAndIdentifier(controller,
          controller.dataset.controller)?.initialize()
      })
    }
  }
}
