// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['favourite', 'favouriteBtn']
  static values = { url: String }

  connect() {
    document.addEventListener('DOMContentLoaded', this._load_job_like.bind(this));
  }

  disconnect() {
    document.removeEventListener('DOMContentLoaded', this._load_job_like.bind(this));
  }

  onCreateJobLike(event) {
    event.preventDefault();
    const url = event.params.url;
    const jobId = event.params.jobId;
    const formData = new FormData();
    formData.append('proposition_form[job_id]', jobId)

    Rails.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        this.favouriteTarget.innerHTML = response.favourite;
        this.favouriteBtnTarget.innerHTML = response.favourite_btn;
      }
    });
  }

  onUpdateJobLike(event) {
    event.preventDefault();
    const url = event.params.url;

    Rails.ajax({
      type: "PUT",
      url: url,
      dataType: "json",
      success: (response) => {
        this.favouriteTarget.innerHTML = response.favourite;
        this.favouriteBtnTarget.innerHTML = response.favourite_btn;
      }
    });
  }

  _load_job_like() {
    const url = this.urlValue;
    const uniqueUrl = `${url}?_=${new Date().getTime()}`;
    Rails.ajax({
      type: "GET",
      url: uniqueUrl,
      dataType: "json",
      success: (response) => {
        if (this.hasFavouriteTarget)
          this.favouriteTarget.innerHTML = response.favourite;
        this.favouriteBtnTarget.innerHTML = response.favourite_btn;
      }
    });
  }
}
