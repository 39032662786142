// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["form", "btnOpenSuccessModal", "btnCloseApplyModal", "btnOpenConfirmModal"];

  onReportViolation(event) {
    event.preventDefault();

    const method = event.params.method;
    const url = event.params.url;
    const form = this.formTarget;
    const formData = new FormData(form);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        if (response.success) {
          this.btnOpenSuccessModalTarget.click();
        } else {
          this.formTarget.innerHTML = response.body.querySelector(`#${this.formTarget.id}`).innerHTML;
          this.btnCloseApplyModalTarget.click();
        }
      },
      error: (error) => {
        this.btnCloseApplyModalTarget.click();
        alert('エラーが発生しました。ページをリロードしてください。');
      }
    });
  }

  onValidateForm(event) {
    event.preventDefault();

    const url = event.params.url;
    const method = event.params.method;
    const form = this.formTarget;
    const formData = new FormData(form);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        if (response.success) {
          this.btnOpenConfirmModalTarget.click();
        } else {
          this.formTarget.innerHTML = response.body.querySelector(`#${this.formTarget.id}`).innerHTML;
        }
      },
      error: (error) => {
        alert('エラーが発生しました。ページをリロードしてください。');
      }
    });
  }
}
