// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['favoriteBtn', 'favoriteCheckedBtn']

  onUpdateJobLike(event) {
    event.preventDefault();
    const jobId = event.params.jobId;
    const propositionId = event.params.propositionId;
    if (!propositionId) {
      this._createJobLike(jobId, event)
    } else {
      this._updateJobLike(propositionId)
    }
  }

  _createJobLike(jobId, event) {
    const formData = new FormData();
    formData.append('proposition_form[job_id]', jobId)

    Rails.ajax({
      type: "POST",
      url: '/v1/propositions',
      dataType: "json",
      data: formData,
      success: (response) => {
        this.favoriteBtnTarget.classList.toggle('d-none');
        this.favoriteCheckedBtnTarget.classList.toggle('d-none');
        this.favoriteBtnTarget.dataset.favoritePropositionIdParam = response?.data?.proposition?.id
      },
      error: (response) => {
        if (response.code === 'unauthorized') {
          window.location.href = '/candidate/login'
        }
      }
    });
  }

  _updateJobLike(propositionId) {
    Rails.ajax({
      type: "PUT",
      url: `/v1/propositions/${propositionId}`,
      dataType: "json",
      success: (response) => {
        this.favoriteBtnTarget.classList.toggle('d-none');
        this.favoriteCheckedBtnTarget.classList.toggle('d-none');
      },
      error: (response) => {
        if (response.code === 'unauthorized') {
          window.location.href = '/candidate/login'
        }
      }
    });
  }
}
