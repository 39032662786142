// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'messageAttachmentName', 'inputFile']

  onSubmitForm = () => {
    this.formTarget.submit();
  }

  showScoutMessage(event) {
    event.preventDefault();
    const target                    = event.currentTarget;
    const scoutsDetail              = target.closest('.scouts-detail');
    const scoutsDetailScoutMessages = scoutsDetail.querySelector('.scouts-detail__scout-messages');
    const scoutsDetailJobPage       = scoutsDetail.querySelector('.scouts-detail__job-page');
    const showJobDetailDiv          = target.closest('.child_link').nextElementSibling;
    const jobDetailLink             = target.closest('.col').querySelector('.job-detail');

    scoutsDetailScoutMessages.classList.remove('d-none');
    target.closest('.child_link').classList.add('border-info', 'border-2', 'border-bottom');
    target.classList.remove('color-grey-70');
    target.classList.add('color-blue-primary');
    jobDetailLink.classList.remove('color-blue-primary');
    jobDetailLink.classList.add('color-grey-70');
    scoutsDetailJobPage.classList.add('d-none');
    showJobDetailDiv.classList.remove('border-info', 'border-2', 'border-bottom');
  }

  showJobDetail(event) {
    event.preventDefault();
    const target                    = event.currentTarget;
    const scoutsDetail              = target.closest('.scouts-detail');
    const scoutsDetailScoutMessages = scoutsDetail.querySelector('.scouts-detail__scout-messages');
    const scoutsDetailJobPage       = scoutsDetail.querySelector('.scouts-detail__job-page');
    const showScoutMessageDiv       = target.closest('.child_link').previousElementSibling;
    const applyMessageLink          = target.closest('.col').querySelector('.scout-messages');

    scoutsDetailJobPage.classList.remove('d-none');
    target.closest('.child_link').classList.add('border-info', 'border-2', 'border-bottom');
    target.classList.remove('color-grey-70');
    target.classList.add('color-blue-primary');
    applyMessageLink.classList.remove('color-blue-primary');
    applyMessageLink.classList.add('color-grey-70');
    scoutsDetailScoutMessages.classList.add('d-none');
    showScoutMessageDiv.classList.remove('border-info', 'border-2', 'border-bottom');
  }

  fileOnchange(event) {
    const attachmentFile = event.target.files[0];
    if (attachmentFile) {
      let fileName = attachmentFile.name;
      fileName = fileName.length > 20 ? fileName.slice(0, 17) + '...' + fileName.substring(fileName.lastIndexOf('.')) : fileName;
      this.messageAttachmentNameTarget.innerHTML = fileName;
      this.messageAttachmentNameTarget.nextSibling.classList.remove('d-none');
    } else {
      this.messageAttachmentNameTarget.innerHTML = '';
    }
  }

  onResetAttachFile() {
    this.messageAttachmentNameTarget.nextSibling.classList.add('d-none')
    this.messageAttachmentNameTarget.innerHTML = '';
    this.inputFileTarget.value = '';
    const errorElement = this.inputFileTarget.nextSibling;
    if(errorElement) {
      errorElement.remove();
    }
  }
}
