// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import Quill from 'quill';

export default class extends Controller {
  static targets = ['htmlContent'];

  connect() {
    this.quillInit();
  }

  quillInit() {
    let fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = this.sizeWhiteList;
    Quill.register(fontSizeStyle, true);

    const quill = new Quill(this.htmlContentTarget);
    quill.setContents(JSON.parse(this.htmlContentTarget.textContent));

    const htmlContent = this.htmlContentTarget;
    const justHtml = quill.root.innerHTML;
    htmlContent.innerHTML = justHtml;
  }

  get sizeWhiteList() {
    return [
      '8px',
      '9px',
      '10px',
      '11px',
      '12px',
      '13px',
      '14px',
      '15px',
      '16px',
      '17px',
      '18px',
      '19px',
      '20px',
      '21px',
      '22px',
      '24px',
      '26px',
      '28px',
      '30px',
      '32px',
    ]
  }
}
