// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['favoriteBtn', 'applyBtn', 'modalApply', 'btnCloseDeclineModal', 'btnOpenDeclineModal', 'declinedBtn']
  static values = { url: String }

  connect = () => {
    // AF-MATCHING-1131 No.4: Show modal after logged in
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    const localStorageItem = JSON.parse(localStorage.getItem("interview_apply_modal"));

    if (localStorageItem) {
      if (this.hasApplyBtnTarget) {
        this.applyBtnTarget.click();
      }
      localStorage.removeItem("interview_apply_modal");
      localStorage.removeItem("interview_modal_id");
    } else if (action === 'interview_apply_modal') {
      // Remove action param from URL
      urlParams.delete('action');
      const params = urlParams.toString();
      const newUrl = params ? `${window.location.pathname}?${params}` : window.location.pathname;
      window.history.replaceState({}, '', newUrl);

      const isLoggedIn = localStorage.getItem('access_token').length > 0
      if (isLoggedIn) this.applyBtnTarget.click();
    }
  }

  onActionJobLike(event) {
    event.preventDefault();
    this.favoriteBtnTarget.classList.add('btn-disabled');
    const url = event.params.url;
    const jobId = event.params.jobId;
    const method = event.params.method;
    const jobLikeId = event.params.jobLikeId;
    const formData = new FormData();
    formData.append("job_id", jobId);
    formData.append("job_like_id", jobLikeId);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        this.favoriteBtnTarget.innerHTML = response.body.querySelector('.js-btn-agent-job-like').innerHTML;
      },
      error: (error) => {
        alert('エラーが発生しました。ページをリロードしてください。');
      },
      complete: () => {
        this.favoriteBtnTarget.classList.remove('btn-disabled');
      }
    });
  }

  onGuestSubmitApplyJob = () => {
    localStorage.setItem("redirect_url", window.location.pathname + window.location.search);
    window.location.href = event.currentTarget.dataset.urlParam;
  }

  onOpenModal(event) {
    const modalId = event.params.modalId;
    this.modalApplyTarget.dataset.applyBtnId = event.currentTarget.id;
    this._toggleModal(modalId);
  }

  onCloseModal(event) {
    const modalId = event.params.modalId;
    this._toggleModal(modalId);
  }

  onApplyJob(event) {
    event.preventDefault();

    const currentBtnApply = document.getElementById(this.modalApplyTarget.dataset.applyBtnId);
    currentBtnApply.classList.add('btn-disabled');

    const url = event.params.url;
    const jobId = event.params.jobId;
    const jobTitle = event.params.jobTitle;
    const companyName = event.params.companyName;
    const method = event.params.method;
    const formData = new FormData();
    formData.append("job_id", jobId);
    formData.append("job_title", jobTitle);
    formData.append("company_name", companyName);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        const textSpan = currentBtnApply.querySelector('.js-text-btn-apply');
        if (textSpan) {
          textSpan.textContent = "応募済み";
          this.favoriteBtnTarget.classList.add('d-none');
          this.btnOpenDeclineModalTarget.classList.add('d-none')
        } else {
          this.favoriteBtnTarget.classList.add('d-none');
          currentBtnApply.classList.add('pe-none');
          const imgElement = currentBtnApply.querySelector('img');
          if (imgElement) {
            const newSrc = imgElement.src.replace('icon-apply.svg', 'icon-apply-grey.svg');
            imgElement.src = newSrc;
          }
        }
        this._toggleModal('agentApplyJobModal');
      },
      error: (error) => {
        alert('エラーが発生しました。ページをリロードしてください。');
      }
    });
  }

  onDeclineJob(event) {
    event.preventDefault();
    const url = event.params.url;
    const matchingId = event.params.matchingId;
    const method = event.params.method;
    const formData = new FormData();
    const btnApplyPC = document.getElementById('applyBtnJobPc');
    const btnApplySP = document.getElementById('applyJobBtnSp');
    formData.append("matching_id", matchingId);

    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        this.favoriteBtnTarget.classList.add('d-none');
        this.btnOpenDeclineModalTarget.classList.add('d-none')
        btnApplyPC.classList.remove('d-md-flex');
        btnApplyPC.classList.add('d-none');
        btnApplySP.classList.add('d-none');
        this.declinedBtnTarget.classList.remove('d-none');
        this.btnCloseDeclineModalTarget.click();
      },
      error: (error) => {
        alert('エラーが発生しました。ページをリロードしてください。');
      }
    });
  }

  _toggleModal(id) {
    const modalElement = document.getElementById(id);
    const backdrop = document.createElement('div');
    backdrop.className = 'modal-backdrop fade show';

    if (modalElement.classList.contains('show')) {
      modalElement.classList.remove('show');
      document.body.classList.remove('modal-open');
      modalElement.style.display = 'none';
      document.querySelectorAll('.modal-backdrop.fade.show').forEach(el => el.remove());
    } else {
      modalElement.classList.add('show');
      document.body.classList.add('modal-open');
      modalElement.style.display = 'block';
      document.body.appendChild(backdrop);
    }
  }
}
