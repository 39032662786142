import { Controller } from "@hotwired/stimulus"
import ApiLoaderPlugin from "./plugin"

export default class extends Controller {
  connect() {
    this.loader = new ApiLoaderPlugin(this.element, {
      method: this.element.dataset.method,
      headers: this.getHeaders(),
      body: this.getRequestBody()
    })
  }

  getHeaders() {
    try {
      return JSON.parse(this.element.dataset.headers || '{}')
    } catch {
      return ApiLoaderPlugin.DEFAULTS.headers
    }
  }

  getRequestBody() {
    try {
      return JSON.parse(this.element.dataset.body || '{}')
    } catch {
      return null
    }
  }
}
