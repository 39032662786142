// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["form", "submitButton", "withdrawalPlatform"];
  static values = { platform: String };

  onChangeAgreement(event) {
    const isChecked = event.target.checked;
    this.submitButtonTarget.disabled = !isChecked;
    if (isChecked) {
      this.submitButtonTarget.classList.remove("btn-disabled")
    } else {
      this.submitButtonTarget.classList.add("btn-disabled")
    }
  }

  onChangeWithdrawPlatform(event) {
    const checkboxes = this.element.querySelectorAll("input.js-platform[type='checkbox']");
    const checkedValues = Array.from(checkboxes)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    let platformValue;
    if (checkedValues.length === 2) {
      platformValue = 'both';
    } else if (checkedValues.length === 1) {
      platformValue = checkedValues[0];
    } else {
      platformValue = '';
    }

    this.withdrawalPlatformTarget.value = platformValue;
  }

  onSubmit(event) {
    const isPlatformAgent = JSON.parse(event.target.dataset.isPlatformAgent);
    const isPlatformDirect = JSON.parse(event.target.dataset.isPlatformDirect);
    const isWithdrawBothPlatform = this.withdrawalPlatformTarget.value === 'both';
    event.preventDefault();
    Rails.ajax({
      url: this.formTarget.action,
      type: 'POST',
      data: new FormData(this.formTarget),
      dataType: 'json',
      success: (response) => {
        this.formTarget.innerHTML = response.entries;
        if (response.status === 200) {
          if (isPlatformAgent || isPlatformDirect || isWithdrawBothPlatform) {
            this._toggleModal('withdrawalPlatformModal');
          } else {
            this._toggleModal('changePlatformModal');
          }
        }
      },
      error: (response) => {
        window.location.reload();
      }
    })
  }

  _toggleModal(id) {
    const modalElement = document.getElementById(id);
    const backdrop = document.createElement('div');
    backdrop.className = 'modal-backdrop fade show';

    if (modalElement.classList.contains('show')) {
      modalElement.classList.remove('show');
      document.body.classList.remove('modal-open');
      modalElement.style.display = 'none';
      document.querySelectorAll('.modal-backdrop.fade.show').forEach(el => el.remove());
    } else {
      modalElement.classList.add('show');
      document.body.classList.add('modal-open');
      modalElement.style.display = 'block';
      document.body.appendChild(backdrop);
    }
  }

  onCloseModal(event) {
    this._toggleModal(`${event.target.dataset.modalId}`);
  }
}
