// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['listCompany', 'listBlockedCompany', 'input', 'itemChecked', 'inputNameCompanies']
  static values = {
    urlNotification: String,
    urlBlockCompany: String
  }

  connect() {
    document.addEventListener("click", this._handleDocumentClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this._handleDocumentClick.bind(this));
  }

  onChangeStatus = (event) => {
    const data = `is_like_received=${this.itemCheckedTarget.checked}`
    Rails.ajax({
      url: event.params.url,
      type: 'patch',
      data: data,
      contentType: 'application/json',
      success: (response) => {
      }
    });
  }

  onAddBlockCompany = (event) => {
    const parentItem = event.target.parentNode;
    const corporateNumber = parentItem.dataset['corporate_number']
    const companyName = parentItem.dataset['company_name']
    const address = parentItem.dataset['location']
    const form = new FormData();
    form.append('blocked_company', corporateNumber);
    form.append('blocked_company_name', companyName);
    form.append('address', address);
    const blockItem = this.listCompanyTarget.querySelector(`#company-corporate-number-${corporateNumber}`);
    const parentNode = blockItem.parentNode
    const htmlContainer = document.querySelector('.js-company-blocked');

    Rails.ajax({
      url: this.urlBlockCompanyValue,
      type: 'post',
      data: form,
      contentType: 'multipart/form-data',
      success: (response) => {
        this._removeItemCompany(parentNode, blockItem);
        const documentHtml = response.documentElement.querySelector('.js-company-blocked').innerHTML;
        htmlContainer.innerHTML = documentHtml;
      }
    });
  }

  onFetchCompanies = () => {
    const data = this.inputTarget.value;
    const htmlContainer = document.querySelector('.js-list-company');
    const loadingElement = document.querySelector('.loading-blocked-company')
    htmlContainer.innerHTML = loadingElement.innerHTML;
    loadingElement.classList.toggle('d-none')

    this._loadCompanies(data, htmlContainer);
  }

  _loadCompanies = (data, htmlContainer) => {
    Rails.ajax({
      url: `${this.urlNotificationValue}?blocked_company_name=${data}`,
      type: 'get',
      success: (response) => {
        this._removeMessageError();
        const documentHtml = response.documentElement.querySelector('.js-list-company').innerHTML;
        htmlContainer.innerHTML = documentHtml;
        const listCompany = document.querySelector('.company-item.list-group-item');
        if (!listCompany) {
          const message =
          `<div class='message_error'>
            <p class="text-danger m-0 pt-2">会社が見つかりません</p>
          </div>`;
          const newDiv = document.createElement('div');
          newDiv.innerHTML = message
          this.listCompanyTarget.appendChild(newDiv);
        }
      },
      error: (xhr, status, error) => {
        this._removeMessageError();
        const errorMessage = `
          <div class='message_error'>
            <p class="text-danger m-0 pt-2">現在一時的にブロック企業の検索が利用できません。</p>
            <p class="text-danger">しばらく時間が経過してから再度お試しください。</p>
          </div>
        `;
        const newDiv = document.createElement('div');
        newDiv.innerHTML = errorMessage
        const searchElement = document.querySelector('.js-search-company');
        searchElement.appendChild(newDiv);
      }
    });
  }

  onRemoveBlockedCompany = (event) => {
    const companyID = event.target.dataset['companyId'];
    const blockedItem = this.listBlockedCompanyTarget.querySelector(`#company-blocked-id-${companyID}`);
    const parentNode = blockedItem.parentNode

    Rails.ajax({
      url: `/candidates/settings/resume_blocked_company?id=${companyID}`,
      type: 'delete',
      success: (response) => {
        this._removeItemCompanyBlocked(parentNode, blockedItem);
      }
    });
  }

  _removeItemCompany = (parentNode, blockItem) => {
    parentNode.remove();
  }

  _removeItemCompanyBlocked = (parentNode, blockItem) => {
    parentNode.removeChild(blockItem);
  }

  _handleDocumentClick = (event) => {
    if (!this.inputNameCompaniesTarget.contains(event.target) && !this.listCompanyTarget.contains(event.target) && !event.target.parentNode.className.includes('company-item')) {
      const listCompany = document.querySelector('.company-item.list-group-item');
      if (listCompany) { listCompany.parentNode.remove(); }
    }
  }
  _removeMessageError = () => {
    const errorr_messsage = document.querySelector('.message_error');
    if (errorr_messsage !== null) errorr_messsage.style.display = 'none';
  }
}
