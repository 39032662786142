// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import { V1_GET_JOBS_AGENT, AGENT_SEARCH_PATH } from "@/constant/routers/path";

export default class extends Controller {
  static targets = [
    'form',
    'modal',
    'items',
    'prefectures',
    'cities',
    'prefectureChildren',
    'itemOccupationChecked',
    'itemPrefectureChecked',
    'jobCount'
  ];

  connect() {
    this.onSearchCountJob();
    this.setupAccordionListeners();
  }

  setupAccordionListeners() {
    document.querySelectorAll('.accordion-button').forEach(button => {
      button.addEventListener('show.bs.collapse', (event) => {
        const parentCheckbox = event.target.closest('.accordion-item').querySelector('input[type="checkbox"]');
        if (parentCheckbox && parentCheckbox.checked) {
          event.preventDefault();
        }
      });
    });
  }

  // Modal関連のメソッド
  onOpenModal() {
    this.removeModalBackdrop();
  }

  onCloseModal() {
    this.removeModalBackdrop();
    document.body.style.overflow = "";
  }

  onCloseDropdown() {
    document.querySelector('.dropdown-search-condition').classList.remove('show');
  }

  // チェックボックス関連のメソッド
  toggleChildCheckboxes(event) {
    const { parentId } = event.target.dataset;
    const isChecked = event.target.checked;
    
    const childCheckboxes = document.querySelectorAll(`input[type="checkbox"][data-parent-id="${parentId}"]`);
    childCheckboxes.forEach(checkbox => checkbox.checked = isChecked);

    // 子チェックボックスがすべて外れた場合、親チェックボックスも外す
    if (!isChecked) {
      const allChildrenUnchecked = Array.from(childCheckboxes).every(checkbox => !checkbox.checked);
      if (allChildrenUnchecked) {
        event.target.checked = false;
      }
    }
  }

  checkParentCheckbox(event) {
    const { parentId } = event.target.dataset;
    const parentCheckbox = document.querySelector(`input[type="checkbox"][data-parent-id="${parentId}"]`);
    const childCheckboxesArray = Array.from(document.querySelectorAll(`input[type="checkbox"][data-parent-id="${parentId}"]`)).filter((input) => input.value !== parentId);
    
    // 子チェックボックスがすべて外れた場合、親チェックボックスも外す
    const allChildrenUnchecked = childCheckboxesArray.every(checkbox => !checkbox.checked);
    if (allChildrenUnchecked) {
      parentCheckbox.checked = false;
    } else {
      parentCheckbox.checked = true;
    }
  }

  onDisableCity(event) {
    const cityItems = document.getElementsByClassName(`parent_${event.target.id}`);
    Array.from(cityItems).forEach(item => {
      item.disabled = !event.target.checked;
    });
  }

  // 保存関連のメソッド
  onSaveOccupation(event) {
    const contentHTML = this.generateOccupationContent(event);
    this.itemOccupationCheckedTarget.innerHTML = contentHTML;
    this.onSearchCountJob();
  }

  onSavePrefecture(event) {
    const contentHTML = this.generatePrefectureContent(event);
    this.itemPrefectureCheckedTarget.innerHTML = contentHTML;
    this.onSearchCountJob();
  }

  onSaveItem(event, item) {
    if (!item.querySelector('input').checked) return '';
    
    const label = item.querySelector('.form-check-label').textContent;
    const id = item.querySelector('input').value;
    return this.createBadgeElement(label, event.params.attribute, id);
  }

  // 削除関連のメソッド
  onRemove(event) {
    event.target.parentNode.remove();
    document.getElementById(event.params.checkbox).checked = false;
    this.onSearchCountJob();
  }

  onRemovePrefecture() {
    event.target.parentNode.remove();
    const checkbox = document.getElementById(event.params.checkbox);
    checkbox.checked = false;
    
    document.querySelectorAll(`.parent_${event.params.checkbox}:checked`)
      .forEach(item => item.checked = false);
    
    this.onSearchCountJob();
  }

  // 検索関連のメソッド
  onSearchCountJob() {
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    // URLSearchParamsオブジェクトを作成
    const params = new URLSearchParams();
    
    // 収入IDから実際の金額（万円単位）への変換マップ
    const incomeMap = {
      '1': 150,
      '2': 200,
      '3': 250,
      '4': 300,
      '5': 350,
      '6': 400,
      '7': 450,
      '8': 500,
      '9': 550,
      '10': 600,
      '11': 650,
      '12': 700,
      '13': 750,
      '14': 800,
      '15': 850,
      '16': 900,
      '17': 950,
      '18': 1000,
      '19': 1100,
      '20': 1200,
      '21': 1300,
      '22': 1400,
      '23': 1500,
      '24': 1600,
      '25': 1700,
      '26': 1800,
      '27': 1900,
      '28': 2000,
      '29': 2500,
      '30': 3000,
      '31': 3500,
      '32': 4000,
      '33': 4500,
      '34': 5000,
      '35': null
    };

    let wageRange = [null, null]
    for (const [key, value] of formData.entries()) {
      if (key === 'wage_min' && value) {
        const amount = incomeMap[value] || value;
        wageRange[0] = amount
      } else if (key === 'wage_max' && value) {
        const amount = incomeMap[value] || value;
        wageRange[1] = amount
      } else if (key === 'job_types[]' && value) {
        params.append('filter[have_hrc_job_types_any][]', value);
      } else if (key === 'prefectures[]' && value) {
        params.append('filter[with_prefecture][]', value);
      } else if (value) {
        params.append(key, value);
      }
    }
    if (wageRange[0] || wageRange[1]) {
      params.append('filter[with_wage_range][]', wageRange[0]);
      params.append('filter[with_wage_range][]', wageRange[1]);
    }
    
    params.append('include[]', 'corporation');
    params.append('include[]', 'prefectures');
    params.append('include[]', 'hrc_skills');
    params.append('include[]', 'hrc_job_types');
    params.append('include[]', 'corporation.corporation_industries');
    
    params.append('sort', '-publication_date_senavi');
    params.append('page[size]', 0); // カウント値を取るだけなのでページサイズを0にする（パフォーマンス改善）
    params.append('page[number]', 1);
    
    const queryString = params.toString();
    const url = V1_GET_JOBS_AGENT + "?" + queryString;
    
    Rails.ajax({
      type: "GET",
      url,
      dataType: "json",
      success: (response) => {
        const { data: { meta: { total_records } } } = response;
        this.updateJobCount(this.jobCountTarget, total_records);
      },
      error: (error) => {
        this.updateJobCount(this.jobCountTarget, 0);
      }
    });
  }

  onSearchCondition(event) {
    event.preventDefault();
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    
    formData.append('include[]', 'corporation');
    formData.append('include[]', 'prefectures');
    formData.append('include[]', 'hrc_skills');
    formData.append('include[]', 'hrc_job_types');
    formData.append('include[]', 'corporation.corporation_industries');
    
    const queryString = new URLSearchParams(formData).toString();
    const url = AGENT_SEARCH_PATH + "&" + queryString;
    window.location.href = url
  }

  // ユーティリティメソッド
  removeModalBackdrop() {
    document.querySelectorAll(".modal-backdrop").forEach(element => {
      element.classList.remove("modal-backdrop", "fade", "show");
    });
  }

  createBadgeElement(label, attribute, id) {
    return `<span class='badge bg-light border text-dark fw-normal size-12 lh-12'> ${label}
      <button class='btn-close btn-close-primary size-10 ms-2 color-primary' type='button' 
      data-action='click->dropdown-search-condition#onRemove' 
      data-dropdown-search-condition-checkbox-param='${attribute}_${id}'></button></span>`;
  }

  generateOccupationContent(event) {
    let contentHTML = '';

    this.itemsTargets.forEach(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    });

    return contentHTML;
  }

  generatePrefectureContent(event) {
    let contentHTML = '';
    
    this.prefecturesTargets.forEach(item => {
      if (item.querySelector('input').checked) {
        const prefectureId = item.querySelector('input').value;
        const prefectureLabel = item.querySelector(`.form-check-label[for='prefecture_id_${prefectureId}']`).textContent;
        let prefectureContent = prefectureLabel;

        item.querySelectorAll(`input[name='prefecture_${prefectureId}_city']`).forEach(city => {
          if (city.checked) {
            const cityLabel = item.querySelector(`.form-check-label[for='prefecture_id_${city.value}']`).textContent;
            prefectureContent += `/${cityLabel}`;
          }
        });

        contentHTML += this.createBadgeElement(prefectureContent, event.params.attribute, prefectureId);
      }
    });

    return contentHTML;
  }

  getFormDataForSearch() {
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    
    const occupations = this.getOccupationIds(form);
    const prefectures = this.getPrefectureIds(form);
    const cities = this.getCityIds(form, prefectures);
    const { min_income, max_income } = this.getAnnualIncome(form);

    occupations.forEach(occupation => formData.append("job_types[]", occupation));
    prefectures.forEach(prefecture => formData.append("prefectures[]", prefecture));
    cities.forEach(city => formData.append("cities[]", city));

    if (min_income) formData.append('min_annual_income', min_income);
    if (max_income) formData.append('max_annual_income', max_income);

    return formData;
  }

  // フォームデータ取得メソッド
  getOccupationIds(form) {
    return form.getAll('occupation_ids');
  }

  getPrefectureIds(form) {
    return form.getAll('prefecture_ids');
  }

  getCityIds(form, prefectures) {
    return prefectures.flatMap(prefecture => 
      form.getAll(`prefecture_${prefecture}_city`)
    );
  }

  getAnnualIncome(form) {
    return {
      min_income: form.get('min_income_id'),
      max_income: form.get('max_income_id')
    };
  }

  // アニメーション関連のメソッド
  updateJobCount(element, number) {
    let upto = 0;
    const jump = Math.floor(number / 100) + 1;
    
    const counts = setInterval(() => {
      upto = Math.min(upto + jump, number);
      element.textContent = upto;
      
      if (upto === number) {
        clearInterval(counts);
      }
    }, 20);
  };

  _getFormDataSearchCondition(form, formData) {
    const occupations = this.getOccupationIds(form) || [];
    const prefectures = this.getPrefectureIds(form) || [];
    const cities = this.getCityIds(form, prefectures) || [];
    const annualIncome = this.getAnnualIncome(form) || [];
    occupations.forEach(occupation => formData.append("job_types[]", occupation));
    prefectures.forEach(prefecture => formData.append("prefectures[]", prefecture));
    if (annualIncome.min_income != '') {
      formData.append('wage_min', annualIncome.min_income);
    }
    if (annualIncome.max_income != '') {
      formData.append('wage_max', annualIncome.max_income);
    }
  }
}
