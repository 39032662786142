// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  onShowPassword(event) {
    const togglePassword = event.target;
    const passwordInput = togglePassword.parentNode.querySelector('.js-password');
    const passwordType = passwordInput.type;

    togglePassword.classList.toggle('fa-eye-slash');
    togglePassword.classList.toggle('fa-eye');
    passwordInput.type = passwordType === 'password' ? 'text' : 'password';
  }
}
