// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["applyModal", "modalCompanyName", "modalJobName", 'btnOpenApplyModal', 'btnCloseApplyModal'];

  onActionJobLike(event) {
    event.preventDefault();

    const url = event.params.url;
    const jobId = event.params.jobId;
    const currentFavoriteBtn = document.getElementById('js-favorite-button-' + jobId);
    currentFavoriteBtn.classList.add('btn-disabled');
    const method = event.params.method;
    const jobLikeId = event.params.jobLikeId;
    const isApply = event.params.isApply;
    const currentBtn = event.target;
    const formData = new FormData();
    formData.append("job_id", jobId);
    formData.append("job_like_id", jobLikeId);
    formData.append("is_apply", isApply);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        currentFavoriteBtn.outerHTML = response.body.innerHTML;
      },
      error: (error) => {
        alert('エラーが発生しました。ページをリロードしてください。');
      },
      complete: () => {
        currentFavoriteBtn.classList.remove('btn-disabled');
      }
    });
  }

  onOpenModal(event) {
    const modalId = event.params.modalId;
    const jobId = event.params.jobId;
    const companyName = event.params.companyName;
    const jobName = event.params.jobName;

    this.modalCompanyNameTarget.textContent = companyName;
    this.modalJobNameTarget.textContent = jobName;

    this.applyModalTarget.dataset.jobId = jobId;
    this.btnOpenApplyModalTarget.click();
  }

  onApplyJob(event) {
    event.preventDefault();
    const url = event.params.url;
    const jobId = this.applyModalTarget.dataset.jobId;
    const currentApplyBtn = document.getElementById('js-apply-button-' + jobId);
    const favoriteBtn = document.getElementById('js-favorite-button-' + jobId);
    const method = event.params.method;
    const formData = new FormData();
    formData.append("job_id", jobId);
    Rails.ajax({
      type: method,
      url: url,
      dataType: "json",
      data: formData,
      success: (response) => {
        currentApplyBtn.outerHTML = response.body.innerHTML;
        favoriteBtn.classList.add('d-none');
        this.btnCloseApplyModalTarget.click();
      },
      error: (error) => {
        this.btnCloseApplyModalTarget.click();
        alert('エラーが発生しました。ページをリロードしてください。');
      }
    });
  }
}
