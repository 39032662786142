// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["form", "buttonApply"]

  connect = () => {
    this._autoShowModal()
  }

  onShowJobApplicationModal = (event) => {
    this._toggleModal('js-apply-job-modal')
  }

  onSubmitJobApply = (event) => {
    event.preventDefault();
    const jobId = event.currentTarget.dataset.propositionJobIdParam
    const url = this.formTarget.action + '?job_id=' + jobId
    const method = this.formTarget.method
    const formData = new FormData(this.formTarget);

    Rails.ajax({
      url: url,
      type: method,
      data: formData,
      success: (response) => {
        if (response.status === 200) {
          this._toggleModal("js-apply-job-modal")
          this._toggleModal("js-apply-job-success-modal")
        } else {
          const createForm = document.getElementById('js-apply-job-modal')
          const newForm = response.body.querySelector("#new_propositions_job_applications_create_form")
          createForm.querySelector("#new_propositions_job_applications_create_form").replaceWith(newForm)
          const accordionButton = document.querySelector('[data-bs-target="#apply-message"]');
          const accordionContent = document.querySelector('#apply-message');
          this._openAccordion(accordionButton, accordionContent);
        }
      },
      error: (xhr, status, error) => {
        switch (error.status) {
          case 404:
            const errorMessage = JSON.parse(error.responseText).message;
            alert(errorMessage);
            window.location.reload();
            break;
          case 500:
            alert('サーバー内部でエラーが発生しました。時間をおいて再度お試しください。');
            break;
          default:
            console.error(`Unexpected error: ${error.status}`);
        }
      }
    });
  }

  onCloseModal = (event) => {
    event.preventDefault();
    const modalElementSuccess = document.getElementById('js-apply-job-success-modal');
    modalElementSuccess.classList.remove("show");
    document.body.classList.remove("modal-open");
    modalElementSuccess.style.display = "none";
    window.location.reload()
  }

  onSaveModalApply = () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    localStorage.setItem("form_validation", true);
  }

  onGuestSubmitApplyJob = () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    const applyMessage = document.querySelector('#apply-message textarea');
    if (applyMessage) localStorage.setItem("apply_message", applyMessage.value);
    window.location.href = event.currentTarget.dataset.urlParam;
  }

  // private

  _autoShowModal = () => {
    if (this.hasButtonApplyTarget) {
      const urlParams = new URLSearchParams(window.location.search);
      const action = urlParams.get('action');
      const localStorageItem = JSON.parse(localStorage.getItem("interview_apply_modal"));
      const isLoggedIn = localStorage.getItem('access_token').length > 0

      if (!isLoggedIn) return

      if (localStorageItem) {
        this._onShowModalApply(this.buttonApplyTarget, true);
        localStorage.removeItem("interview_apply_modal");
        localStorage.removeItem("interview_modal_id");
      } else if (action === 'interview_apply_modal') {
        this._onShowModalApply(this.buttonApplyTarget, true);
        urlParams.delete('action');
        const params = urlParams.toString();
        const newUrl = params ? `${window.location.pathname}?${params}` : window.location.pathname;
        window.history.replaceState({}, '', newUrl);
      }
    }
  }

  _toggleModal = (id) => {
    const modalElement = document.getElementById(`${id}`);
    if (modalElement.classList.contains("show")) {
      modalElement.classList.remove("show");
      document.body.classList.remove("modal-open");
      modalElement.style.display = "none";
    } else {
      modalElement.classList.add("show");
      document.body.classList.add("modal-open");
      modalElement.style.display = "block";
    }
  }

  _onShowModalApply = (button, isShowModal = false) => {
    const applyMessage = document.querySelector('#apply-message textarea');
    if (button && isShowModal) {
      button.click();
      if (localStorage.getItem('apply_message')) {
        const accordionButton = document.querySelector('[data-bs-target="#apply-message"]');
        const accordionContent = document.querySelector('#apply-message');
        this._openAccordion(accordionButton, accordionContent);
        applyMessage.value = localStorage.getItem("apply_message");
        localStorage.removeItem("apply_message");
      }
    }
  }

  _openAccordion(accordionButton, accordionContent) {
    accordionContent.classList.add('show');
    accordionButton.classList.remove('collapsed');
    accordionButton.setAttribute('aria-expanded', 'true');
  }
}
