import { createConsumer } from "@rails/actioncable";
import { COMPANY_SOCKET_PATH } from '@/configs';
import Rails from "@rails/ujs";

const consumer = createConsumer(COMPANY_SOCKET_PATH)

consumer.subscriptions.create("MessageNotificationsChannel", {
  received(data) {
    const candidate_id = data.candidate_id;
    Rails.ajax({
      type: "GET",
      url: `/message/notifications?candidate_id=${candidate_id}`,
      dataType: "json",
      success: (response) => {
        //menu-footer
        if (document.getElementById("js-footer-message-notification-apply") && response) {
          document.getElementById("js-footer-message-notification-scout").innerHTML = response.num_scouted;

          if (response?.num_applicants && Number(response.num_applicants) > 0) {
            document.getElementById("js-footer-message-notification-apply").classList.remove("d-none");
          }

          if (response?.num_like && Number(response.num_like) > 0) {
            document.getElementById("js-footer-message-notification-favorite").classList.remove("d-none");
          }
        }

        //menu-header
        if (document.getElementById("js-header-message-notification-apply") && response) {
          document.getElementById("js-header-message-notification-scout").innerHTML = response.num_scouted;

          if (response?.num_applicants && Number(response.num_applicants) > 0) {
            document.getElementById("js-header-message-notification-apply").classList.remove("d-none");
          }

          if (response?.num_like && Number(response.num_like) > 0) {
            document.getElementById("js-header-message-notification-favorite").classList.remove("d-none");
          }
        }
      }
    });
  }
});
