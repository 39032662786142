// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import Quill from 'quill';

export default class extends Controller {
  static targets = ['entries', 'htmlContent'];

  connect() {
    if (this.hasEntriesTarget) {
      this.quillInit();
    }
  }

  quillInit() {
    const quill = new Quill(this.entriesTarget);
    let content = this.entriesTarget.textContent;
    try {
      let parsedContent = JSON.parse(content);
      if (parsedContent && parsedContent.ops) {
        parsedContent.ops = parsedContent.ops.filter(op => !(op.insert && typeof op.insert === 'object' && op.insert.image));
        quill.setContents(parsedContent.ops);
      }
    } catch (e) {
      quill.setText(content);
    }
    const justHtml = quill.root.innerHTML;
    this.htmlContentTarget.innerHTML = justHtml;
    this.truncateContent();
  }
  
  truncateContent() {
    const htmlContent = this.htmlContentTarget;
    let textContent = htmlContent.textContent || htmlContent.innerText || "";
    if (htmlContent.dataset.maxTextLength && textContent.length > parseInt(htmlContent.dataset.maxTextLength)) {
      textContent = textContent.substr(0, parseInt(htmlContent.dataset.maxTextLength)) + '...';
      htmlContent.textContent = textContent;
    }
  }
}
