// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  onChangeStatus(event) {
    event.preventDefault();
    const checkbox = event.target;
    Rails.ajax({
      url: this.formTarget.action,
      type: 'PATCH',
      data: new FormData(this.formTarget),
      success: (response) => {
        checkbox.checked = !checkbox.checked;
        checkbox.classList.remove('is-valid');
      }
    });
  }
}
