// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'messageAttachmentName', 'entries', 'pagination', 'replyForm', 'title', 'text', 'inputFile']

  onScroll() {
    if (this.debouncedScroll) {
      clearTimeout(this.debouncedScroll);
    }

    this.debouncedScroll = setTimeout(() => {
      const nextPage = this.paginationTarget.querySelector("a[rel='next']");
      if (nextPage == null) { return }

      const url    = nextPage.href;
      const body   = document.body;
      const html   = document.documentElement;
      const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      if (window.pageYOffset >= height - window.innerHeight - 1) {
        this.loadMore(url)
      }
    }, 200);
  }

  loadMore(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (response) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", response.entries);
        this.paginationTarget.innerHTML = response.pagination
      }
    });
  }

  onSubmitForm = () => {
    this.formTarget.submit();
  }

  showApplyMessage(event) {
    event.preventDefault();
    const target               = event.currentTarget;
    const appliesDetail        = target.closest('.apply-detail');
    const applyMessages        = appliesDetail.querySelector('.apply-detail-messages');
    const appliesDetailJobPage = appliesDetail.querySelector('.apply-detail-job-page');
    const showJobDetailDiv     = target.closest('.child-link').previousElementSibling;
    const jobDetailLink        = target.closest('.col').querySelector('.job-detail');

    applyMessages.classList.remove('d-none');
    this.updateClassList(target, jobDetailLink, showJobDetailDiv);
    appliesDetailJobPage.classList.add('d-none');
  }

  showJobDetail(event) {
    event.preventDefault();
    const target               = event.currentTarget;
    const appliesDetail        = target.closest('.apply-detail');
    const applyMessages        = appliesDetail.querySelector('.apply-detail-messages');
    const appliesDetailJobPage = appliesDetail.querySelector('.apply-detail-job-page');
    const showApplyMessageDiv  = target.closest('.child-link').nextElementSibling;
    const applyMessageLink     = target.closest('.col').querySelector('.apply-messages');

    appliesDetailJobPage.classList.remove('d-none');
    this.updateClassList(target, applyMessageLink, showApplyMessageDiv);
    applyMessages.classList.add('d-none');
  }

  updateClassList(target, targetParent, elementSibling) {
    target.closest('.child-link').classList.add('border-info', 'border-2', 'border-bottom');
    target.classList.remove('color-grey-70');
    target.classList.add('color-blue-primary');
    targetParent.classList.remove('color-blue-primary');
    targetParent.classList.add('color-grey-70');
    elementSibling.classList.remove('border-info');
  }

  fileOnchange(event) {
    const attachmentFile = event.target.files[0];
    if (attachmentFile) {
      let fileName = attachmentFile.name;
      fileName = fileName.length > 20 ? fileName.slice(0, 17) + '...' + fileName.substring(fileName.lastIndexOf('.')) : fileName;
      this.messageAttachmentNameTarget.innerHTML = fileName;
      this.messageAttachmentNameTarget.nextSibling.classList.remove('d-none')
    } else {
      this.messageAttachmentNameTarget.innerHTML = '';
    }
  }

  onResetAttachFile() {
    this.messageAttachmentNameTarget.nextSibling.classList.add('d-none')
    this.messageAttachmentNameTarget.innerHTML = '';
    this.inputFileTarget.value = '';
    const errorElement = this.inputFileTarget.nextSibling;
    if(errorElement) {
      errorElement.remove();
    }
  }

  onCountCharacters(event) {
    const characters = event.currentTarget.value.length;
    const counterId = event.params.attribute;
    const maxTextLength = event.params.maxTextLength;
    const counter = document.querySelector(`span[data-counter="${counterId}"]`);
    counter.textContent = characters;
    counter.classList.toggle('text-danger', characters > maxTextLength);
  }
}
