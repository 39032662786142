import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

export default class extends Controller {
  static targets = ['swiperWrapper', 'navigation', 'pagination']
  static values = {
    breakpoint: Array,
    spacebetween: Array,
    initialSlides: Array,
    waitForData: { type: Boolean, default: false },
    slideWidth: { type: String, default: null },
    autoplay: { type: Boolean, default: false },
    autoplayDelay: { type: Number, default: 2500 },
    autoplayDisableOnInteraction: { type: Boolean, default: false }
  }

  connect() {
    if (this.waitForDataValue) {
      this.element.addEventListener('api-loader:success', this.initializeSwiper.bind(this))
    } else {
      this.initializeSwiper()
    }
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
    if (this.waitForDataValue) {
      this.element.removeEventListener('api-loader:success', this.initializeSwiper.bind(this))
    }
  }

  initializeSwiper() {
    setTimeout(() => {
      if (this.swiper) {
        this.swiper.destroy()
      }

      this.configure()
    }, 0)
  }

  configure() {
    const swiperConfig = {
      modules: [Navigation, Pagination, Scrollbar, Autoplay],
      centeredSlides: screen.width < 1024,
      slidesPerView: "auto",
      loop: true,
      keyboard: {
        enabled: true
      },
      pagination: {
        el: ".swiper-pagination-bullets-primary",
        clickable: true,
        enabled: true,
      },

      breakpoints: {
        390: {
          slidesPerView: this.getOptimalSlidesPerView(0),
          spaceBetween: this.spacebetweenValue[0] || 10,
          initialSlide: this.initialSlidesValue[0]
        },

        640: {
          slidesPerView: this.getOptimalSlidesPerView(1),
          spaceBetween: this.spacebetweenValue[1] || 10,
          initialSlide: this.initialSlidesValue[1]
        },

        1024: {
          slidesPerView: this.getOptimalSlidesPerView(2),
          spaceBetween: this.spacebetweenValue[2] || 10,
          initialSlide: this.initialSlidesValue[2]
        }
      },

      on: {
        init: (swiper) => {
          this.updateNavigationVisibility(swiper);
        },
        resize: (swiper) => {
          this.updateNavigationVisibility(swiper);
        }
      }
    }

    if (this.autoplayValue) {
      swiperConfig.autoplay = {
        delay: this.autoplayDelayValue,
        disableOnInteraction: this.autoplayDisableOnInteractionValue
      }
    }

    this.swiper = new Swiper(this.swiperWrapperTarget.getAttribute('data-klass-component'), swiperConfig);
  }

  updateNavigationVisibility(swiper) {
    const totalSlides = swiper.slides.length;
    const slidesPerView = this.getCurrentSlidesPerView();
    const shouldShowNavigation = totalSlides > slidesPerView;

    // Handle slide width
    const slides = this.swiperWrapperTarget.querySelectorAll('.swiper-slide');
    if (totalSlides < slidesPerView) {
      slides.forEach(slide => {
        // Reset class width
        slide.classList.remove('w-18d75', 'w-sm-20d75');

        // Add class width
        if (this.hasSlideWidthValue && this.slideWidthValue) {
          slide.classList.add(this.slideWidthValue);
        } else {
          slide.classList.add('w-18d75', 'w-sm-20d75'); // Default classes
        }
      });
    }

    // Handle show navigation
    this.navigationTargets.forEach(nav => {
      nav.classList.toggle('default-no-pagination', !shouldShowNavigation);
    });

    if (this.hasPaginationTarget) {
      this.paginationTarget.classList.toggle('default-no-pagination', !shouldShowNavigation);
    }
  }

  getCurrentSlidesPerView() {
    const width = window.innerWidth;
    if (width >= 1024) {
      return this.breakpointValue[2];
    } else if (width >= 640) {
      return this.breakpointValue[1];
    } else {
      return this.breakpointValue[0] === "auto" ? 1 : this.breakpointValue[0];
    }
  }

  onPrev() {
    if (this.swiper) {
      this.swiper.slidePrev()
    }
  }

  onNext() {
    if (this.swiper) {
      this.swiper.slideNext()
    }
  }

  getOptimalSlidesPerView(breakpointIndex) {
    const totalSlides = this.swiperWrapperTarget.querySelectorAll('.swiper-slide').length;
    const requestedSlidesPerView = this.breakpointValue[breakpointIndex];

    return totalSlides < requestedSlidesPerView ? "auto" : requestedSlidesPerView;
  }
}
