// @ts-nocheck
import { Controller } from '@hotwired/stimulus';
import { V1_JOBS_PATH, AGENT_SEARCH_PATH } from "@/constant/routers/path";

export default class extends Controller {
  static targets = ['form', 'modal', 'items', 'prefectures', 'cities', 'prefectureChildren',
                    'itemOccupationChecked', 'itemPrefectureChecked', 'jobCount'];

  connect() {
    this.onSearchCountJob();
  }

  onSaveOccupation(event) {
    let $itemChecked = this.itemOccupationCheckedTarget;
    let contentHTML = '';
    this.itemsTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
  }

  onSavePrefecture(event) {
    let $itemChecked = this.itemPrefectureCheckedTarget;
    let contentHTML = '';
    this.prefecturesTargets.map(item => {
      if (item.querySelector('input').checked) {
        contentHTML += this.onSaveItem(event, item);
      }
    })
    $itemChecked.innerHTML = contentHTML;
    this.onSearchCountJob();
  }

  onSaveItem(e, item) {
    let tag = '';
    if (item.querySelector('input').checked) {
      let label = item.querySelector('.form-check-label').textContent;
      let id = item.querySelector('input').value;
      tag = "<span class='badge bg-light border text-dark fw-normal size-12 lh-12 p-3'> " + label +
        "<button class='btn-close btn-close-primary size-10 ms-2 color-primary' type='button' data-action='click->root-modal#onRemove' data-root-modal-checkbox-param='" + e.params.attribute + "_" + id + "'></button></span>";
    }

    return tag;
  }

  onRemove(event) {
    event.target.parentNode.remove();
    document.getElementById(event.params.checkbox).checked = false;
    this.onSearchCountJob();
  }

  onSearchCountJob() {
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    const queryString = new URLSearchParams(formData).toString();
    const url = V1_JOBS_PATH + "?" + queryString;

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (response) => {
        const { data: { pagy: { count } } } = response;
        this.updateJobCount(this.jobCountTarget, count);
      }
    });
  }

  onSearchCondition() {
    event.preventDefault();
    const form = new FormData(this.formTarget);
    const formData = new FormData();
    this._getFormDataSearchCondition(form, formData);
    const queryString = new URLSearchParams(formData).toString();
    const url = AGENT_SEARCH_PATH + "&" + queryString;

    window.location.href = url
  }

  updateJobCount = (element, number) => {
    let upto = 0;
    const jump = Math.floor(number / 100) + 1;
    const counts = setInterval(() => {
      upto += jump;
      if (upto > number) {
        upto = number;
      }
      element.textContent = upto;
      if (upto === number) {
        clearInterval(counts);
      }
    }, 20);
  };

  getOccupationIds(form) {
    return form.getAll("occupation_ids");
  }

  getPrefectureIds(form) {
    return form.getAll("prefecture_ids");
  }

  getCityIds(form, prefectures) {
    let cityIds = []
    prefectures.forEach(item => {
      cityIds = cityIds.concat(form.getAll('prefecture_' + item + '_city'))
    })
    return cityIds
  }

  getAnnualIncome(form) {
    let minIncome = form.get("roots_index_form[annual_min_income_id]");
    let maxIncome = form.get("roots_index_form[annual_max_income_id]");

    return { min_income: minIncome, max_income: maxIncome }
  }

  onDisableCity(event) {
    const citiyItems = document.getElementsByClassName(`parent_${event.target.id}`);
    const prefectureChecked = event.target.checked;
    Array.from(citiyItems).forEach(item => {
      if (prefectureChecked) {
        item.disabled = false
      } else {
        item.disabled = true
      }
    })
  }

  _getFormDataSearchCondition(form, formData) {
    const occupations = this.getOccupationIds(form) || [];
    const prefectures = this.getPrefectureIds(form) || [];
    const cities = this.getCityIds(form, prefectures) || [];
    const annualIncome = this.getAnnualIncome(form) || [];
    occupations.forEach(occupation => formData.append("job_types[]", occupation));
    prefectures.forEach(prefecture => formData.append("prefectures[]", prefecture));
    cities.forEach(city => formData.append("cities[]", city));
    if (annualIncome.min_income != '') {
      formData.append('min_annual_income', annualIncome.min_income);
    }

    if (annualIncome.max_income != '') {
      formData.append('max_annual_income', annualIncome.max_income);
    }
  }
}
