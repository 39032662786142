// @ts-nocheck
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['itemChecked']

  onChangeCompanyAccountFollow = (event) => {
    const companyAccountFollowId = event.target.value;
    const url = event.params.url
    Rails.ajax({
      url: `${url}?id=${companyAccountFollowId}`,
      type: 'patch'
    });
  }
}
